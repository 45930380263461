import React from 'react';
import { Popover } from 'antd'
import { Link } from 'react-router-dom'

import back from '../../../assets/comeback.png'
import logotype from '../../../assets/logotipo.png'
import logotype2 from '../../../assets/aichikikai.png'
import logotype3 from '../../../assets/carrylogo.png'

const ComponentProduct = ({ title, subtitle, description, list01, list02, list03, list04, list05, list06, list07, tooltip1, tooltip2, tooltip3, tooltip4, tooltip5, tooltip6, tooltip7, tooltip8, tooltip9, circle1, circle2, circle3, circle4, circle5, circle6, circle7, circle8, circle9, photography, carrybee01, carrybee02, carrybee03, carrybee04, carrybee05 }) => {
    return ( 
        <div className="component-product-container">
            <div className="left-container">
                <div className="header-container">
                    <div className="comeback">
                        <Link to="/">
                            <img src={back} alt="comeback"/>
                            <p>INICIO</p>
                        </Link>
                    </div>
                    <div className="title">
                        <h3>{title}</h3>
                    </div>
                </div>
                <div className="content">
                    <div className="description">
                        <h4>{subtitle}</h4>
                        <p>{description}</p>
                        <div className="list">
                            <p>{list01}</p>
                            <p>{list02}</p>
                            <p>{list03}</p>
                            <p>{list04}</p>
                            <p>{list05}</p>
                            <p>{list06}</p>
                            <p>{list07}</p>
                        </div>
                        <div>
                            <Link to="/contacto">SOLICITAR COTIZACIÓN</Link>
                        </div>
                    </div>
                    <div className="circles-container">
                        <Popover title={tooltip1}>
                            <img alt={circle1} src={circle1} />
                        </Popover>
                        <Popover title={tooltip2}>
                            <img alt={circle2} src={circle2} />
                        </Popover>
                        <Popover title={tooltip3}>
                            <img alt={circle3} src={circle3} />
                        </Popover>
                        <Popover title={tooltip4}>
                            <img alt={circle4} src={circle4} />
                        </Popover>
                        <Popover title={tooltip5}>
                            <img alt={circle5} src={circle5} />
                        </Popover>
                        <Popover title={tooltip6}>
                            <img alt={circle6} src={circle6} />
                        </Popover>
                        <Popover title={tooltip7}>
                            <img alt={circle7} src={circle7} />
                        </Popover>
                        <Popover title={tooltip8}>
                            <img alt={circle8} src={circle8} />
                        </Popover>
                        <Popover title={tooltip9}>
                            <img alt={circle9} src={circle9} />
                        </Popover>
                    </div>
                </div>
            </div>
            <div className="right-container">
                <div>
                    <img alt="photography" src={photography} id="photography" />
                    <img alt="logo" src={logotype} id="logotype" />
                    <img alt="techno-system" src={logotype2} id="techno-logo" />
                    <img alt="carrybee-logo" src={logotype3} id="carry-logo" />
                    <img alt={carrybee01} src={carrybee01} id="carrybee" />
                    <img alt={carrybee02}  src={carrybee02} id="carrybee2" />
                    <img alt={carrybee03}  src={carrybee03} id="carrybee3" />
                    <img alt={carrybee04} src={carrybee04} id="carrybee4" />
                    <img alt={carrybee05} src={carrybee05} id="carrybee5" />
                </div>
            </div>
        </div>
     );
}
 
export default ComponentProduct;