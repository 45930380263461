import React from "react";
import { Carousel } from "antd";
import imageHome from "../../../assets/image-home.png";
import aichikikai from "../../../assets/aichikikai-white.png";
import carry from "../../../assets/carrywhite.png";
import section02 from "../../../assets/img-section02.png";
import section03 from "../../../assets/img-section03.png";
import { Link } from "react-router-dom";

const CarouselHome = () => {
  return (
    <div className="container-carousel">
      <Carousel dotPosition="right">
        <div className="container-first-page">
          <div id="container">
            <h1>SISTEMAS ELECTRÓNICOS DE SEGURIDAD Y COMUNICACIÓN</h1>
            <h2>VEHÍCULOS AUTOGUIADOS CARRYBEE</h2>
            <div>
              <img alt="aichikikai" className="logotypes" src={aichikikai} />
              <img alt="carrybee" className="logotypes" src={carry} />
            </div>
          </div>
          <img alt="home" id="img-bg" src={imageHome} />
        </div>
        <div className="container-second-page">
          <div className="container-left">
            <div>
              <div>
                <p>01</p>
              </div>
              <Link to="/servicios">VER MÁS</Link>
            </div>
            <img id="img-second-section" alt="services" src={section02} />
          </div>
          <div className="container-right">
            <h2>SERVICIOS</h2>
            <div className="scrollable-container">
              <section>
                <p>
                  TEOPAM pone a sus órdenes los siguientes servicios
                  profesionales:
                </p>
                <div>
                  <p>
                    - Venta y desarrollo de proyectos con vehículos autoguiados
                    "Carry Bee" (AGV).
                  </p>
                  <p>
                    - Desarrollo de proyectos de sistemas eléctronicos de
                    seguridad y comunicaciones: CCTV, protección contra
                    incendios, audio distribuido, control de acceso,
                    automatización y redes de voz y datos.
                  </p>
                  <p>
                    - Servicio de mantenimiento preventivo y correctivo para los
                    sistemas antes especificados.
                  </p>
                  <p>- Asesoría y consultoría, ingeniería de sistemas.</p>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className="container-third-page">
          <div className="container-left">
            <div>
              <div>
                <p>02</p>
              </div>
              <Link to="/productos">VER MÁS</Link>
            </div>
            <img alt="carrybee-photography" src={section03} />
          </div>
          <div className="container-right">
            <h2>PRODUCTOS</h2>
            <div className="scrollable-container">
              <section>
                <p>
                  Somos distribuidores de equipos eléctronicos de seguridad,
                  cámaras, grabadores, equipos de audio, protección contra
                  incendios, equipos de control de acceso y sus accesorios.
                </p>
                <p>
                  Como representantes y distribuidores de vehículos autoguiados
                  de Aichikikai Techno System. También ofrecemos soluciones
                  integrales para su implementación:
                </p>
                <div>
                  <p>- Trazado y modificación de rutas.</p>
                  <p>- Fabricación de remolques a medida.</p>
                  <p>
                    - Integración con sistema automático de envío de rutas con
                    PLC.
                  </p>
                  <p>- Mantenimiento y suministro de refacciones.</p>
                </div>
              </section>
            </div>

            <div className="container-images">
              <img alt="aichikikai2" src={aichikikai} />
              <img alt="carrybee2" src={carry} />
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default CarouselHome;
