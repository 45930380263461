import React from 'react'
import { Pagination } from 'antd'
import { useState } from 'react'
import ComponentProduct from './componentProducts/ComponentProduct'

import photography01 from '../../assets/photographycarry01.png'
import photography02 from '../../assets/photographycarry02.png'
import photography03 from '../../assets/photographycarry03.png'
import photography04 from '../../assets/photographycarry04.png'
import carrybee01 from '../../assets/carry01.png'
import carrybee02 from '../../assets/carry02.png'
import carrybee03 from '../../assets/carry03.png'
import carrybee04 from '../../assets/carry-04.png'
import carrybee05 from '../../assets/carry-04-1.png'
import circle1 from '../../assets/product-circle1.png'
import circle2 from '../../assets/product-circle2.png'
import circle3 from '../../assets/product-circle3.png'
import circle4 from '../../assets/product-circle4.png'
import circle5 from '../../assets/product-circle5.png'
import circle6 from '../../assets/product-circle6.png'
import circle7 from '../../assets/product-circle7.png'
import circle8 from '../../assets/product-circle8.png'
import circle9 from '../../assets/product-circle9.png'
import circle10 from '../../assets/product-circle10.png'
import circle11 from '../../assets/product-circle11.png'
import circle12 from '../../assets/product-circle12.png'


const Products = () => {

    let [ pagination, setPagination ] = useState({
        current: 1
    })

    const onChange = page => {
        setPagination({
          current: page,
        });
    };

    let titleProduct01 = '"Kai" vehículo autoguíado compacto'
    let description01 = "Vehículo básico de tamaño medio para arrastre de remolques mediante un gancho automático para sujetar plataformas de carga. Cuenta con elementos de seguridad como sensores y un par de arranque."
    let list01 = {
        text1 : "- Capacidad de arrastre hasta 400 kg.",
        text2 : "- Tracción en una dirección (sin reversa)."
    }
    let tooltip01 = {
        tooltip1 : "Modelo básico.",
        tooltip2 : "Adecuado para proyectos sencillos.",
        tooltip3 : "Buena capacidad de arrastre."
    }

    let titleProduct02 = '"Dolly" Tipo carga/remolque'
    let description02 = "Este vehículo tiene la capacidad de llevar carga encima de sí mismo, así como arrastrar uno o más remolques."
    let list02 = {
        text1 : "- Capacidad de carga hasta 750kg.",
        text2 : "- Capacidad de arrastre hasta 1000kg.",
        text3 : "- Deteccipon de sobrecarga.",
        text4 : "- Tracción hacia adelante (no reversible)."
    }
    let tooltip02 = {
        tooltip1 : 'Sistemas de energía de respaldo "UPS" (instalación y mantto).',
        tooltip2 : "Capacidad de carga sin remolque adicional.",
        tooltip3 : "Adecuado para proyectos de gran espacio."
    }

    let titleProduct03 = 'Tipo exterior'
    let description03 = "Vehículo robusto cuya principal característica es su capacidad de trabajar en espacios exteriores, ideal para transporte de materiales entre diferentes plantas."
    let list03 = {
        text1 : "- Capacidad de arrastre hasta 3000kg.",
        text2 : "- Resistente a la intemperie.",
        text3 : "- Tracción 4x4 (una sola dirección)."
    }
    let tooltip03 = {
        tooltip1 : "Resistencia y potencia.",
        tooltip2 : "Misma funcionalidad que un AGV convencional.",
        tooltip3 : "Sube pendientes de hasta 5 grados."
    }

    let titleProduct04 = '"Mid-sized" Tamaño medio'
    let description04 = "Vehículo de tamaño medio con funcionalidades mejoradas en comparación al modelo básico (Kai)."
    let list04 = {
        text1 : "- Disponible con doble tracción (adelante y reversa).",
        text2 : "- Capacidad de arraste de 500 a 1500 kg (tracción sencilla); y 750 a 1500 kg (doble tracción).",
        text3 : "- Dispositivo de radiocomunicación para intercomunicación de varios Carry Bee en cruces de rutas.",
        text4 : "- Sensores de tentáculo.",
        text5 : "- Tansceptor óptico infrarrojo para cambio remoto de rutas."
    }
    let tooltip04 = {
        tooltip1 : "Modelo más completo y competitivo para proyectos medianos y grandes.",
        tooltip2 : "Tracción 2x2 y 4x4.",
        tooltip3 : "Apto para uso con cargadores automáticos de baterías."
    }

    return (
        <div className="products-container">
            {(pagination.current === 1) &&
            <ComponentProduct 
                title = {titleProduct01}
                subtitle = {null}
                description = {description01}
                list01 = {list01.text1}
                list02 = {list01.text2}
                list03 = {null}
                list04 = {null}
                list05 = {null}
                list06 = {null}
                list07 = {null}
                tooltip1 = {tooltip01.tooltip1}
                tooltip2 = {tooltip01.tooltip2}
                tooltip3 = {tooltip01.tooltip3}
                tooltip4 = {null}
                tooltip5 = {null}
                tooltip6 = {null}
                tooltip7 = {null}
                tooltip8 = {null}
                tooltip9 = {null}
                circle1 = {circle1}
                circle2 = {circle2}
                circle3 = {circle3}
                circle4 = {null}
                circle5 = {null}
                circle6 = {null}
                circle7 = {null}
                circle8 = {null}
                circle9 = {null}
                photography = {photography01}
                carrybee01 = {carrybee01}
                carrybee02 = {null}
                carrybee03 = {null}
                carrybee04 = {null}
                carrybee05 = {null}
            />
            }
            {(pagination.current === 2) &&
            <ComponentProduct 
                title = {titleProduct02}
                subtitle = {null}
                description = {description02}
                list01 = {list02.text1}
                list02 = {list02.text2}
                list03 = {list02.text3}
                list04 = {list02.text4}
                list05 = {null}
                list06 = {null}
                list07 = {null}
                tooltip1 = {tooltip02.tooltip1}
                tooltip2 = {tooltip02.tooltip2}
                tooltip3 = {tooltip02.tooltip3}
                tooltip4 = {null}
                tooltip5 = {null}
                tooltip6 = {null}
                tooltip7 = {null}
                tooltip8 = {null}
                tooltip9 = {null}
                circle1 = {circle4}
                circle2 = {circle5}
                circle3 = {circle6}
                circle4 = {null}
                circle5 = {null}
                circle6 = {null}
                circle7 = {null}
                circle8 = {null}
                circle9 = {null}
                photography = {photography02}
                carrybee01 = {null}
                carrybee02 = {carrybee02}
                carrybee03 = {null}
                carrybee04 = {null}
                carrybee05 = {null}
            />
            }
            {(pagination.current === 3) &&
            <ComponentProduct 
                title = {titleProduct03}
                subtitle = {null}
                description = {description03}
                list01 = {list03.text1}
                list02 = {list03.text2}
                list03 = {list03.text3}
                list04 = {null}
                list05 = {null}
                list06 = {null}
                list07 = {null}
                tooltip1 = {tooltip03.tooltip1}
                tooltip2 = {tooltip03.tooltip2}
                tooltip3 = {tooltip03.tooltip3}
                tooltip4 = {null}
                tooltip5 = {null}
                tooltip6 = {null}
                tooltip7 = {null}
                tooltip8 = {null}
                tooltip9 = {null}
                circle1 = {circle7}
                circle2 = {circle8}
                circle3 = {circle9}
                circle4 = {null}
                circle5 = {null}
                circle6 = {null}
                circle7 = {null}
                circle8 = {null}
                circle9 = {null}
                photography = {photography03}
                carrybee01 = {null}
                carrybee02 = {null}
                carrybee03 = {carrybee03}
                carrybee04 = {null}
                carrybee05 = {null}
            />
            }
            {(pagination.current === 4) &&
            <ComponentProduct 
                title = {titleProduct04}
                subtitle = {null}
                description = {description04}
                list01 = {list04.text1}
                list02 = {list04.text2}
                list03 = {list04.text3}
                list04 = {list04.text4}
                list05 = {list04.text5}
                list06 = {null}
                list07 = {null}
                tooltip1 = {tooltip04.tooltip1}
                tooltip2 = {tooltip04.tooltip2}
                tooltip3 = {tooltip04.tooltip3}
                tooltip4 = {null}
                tooltip5 = {null}
                tooltip6 = {null}
                tooltip7 = {null}
                tooltip8 = {null}
                tooltip9 = {null}
                circle1 = {circle10}
                circle2 = {circle11}
                circle3 = {circle12}
                circle4 = {null}
                circle5 = {null}
                circle6 = {null}
                circle7 = {null}
                circle8 = {null}
                circle9 = {null}
                photography = {photography04}
                carrybee01 = {null}
                carrybee02 = {null}
                carrybee03 = {null}
                carrybee04 = {carrybee04}
                carrybee05 = {carrybee05}
            />
            }
            <div className="pagination-container">
                <Pagination defaultCurrent="1" total="4" pageSize="1" onChange={onChange} />
                <div>
                    <p>TECNO ÓPTIMO DE AMÉRICA S.A. DE C.V.</p>
                </div>
            </div>
        </div>
    )
}

export default Products