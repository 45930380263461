import React from "react";
import { Link } from "react-router-dom";
import back from "../../assets/comeback.png";
import logo from "../../assets/logo.png";

const Nosotros = () => {
  return (
    <div className="us-container">
      <div className="header-container">
        <div className="comeback">
          <Link to="/">
            <img src={back} alt="comeback" />
            <p>INICIO</p>
          </Link>
        </div>
        <p className="title">¿QUIÉNES SOMOS?</p>
        <div className="language-container">
          <p>
            <a href="#"> EN </a> / <a href="#"> ES </a>
          </p>
        </div>
      </div>
      <div className="content">
        <div className="left-container">
          <img src={logo} alt="logo" />
        </div>
        <div className="right-container">
          <p>
            La empresa Tecno Óptimo de América S.A. de C.V. (TEOPAM) reinicia
            operaciones después de un periodo de inactividad (2018-2020), en el
            cual se reorganiza con un nuevo equipo de trabajo, pero con el mismo
            compromiso y profesionalismo bajo los que fue fundada en 2005.
          </p>
          <p>
            Tomando como premisa la sabiduría ancestral de las culturas
            originarias de México, y la disciplina de trabajo de la cultura
            japonesa (de su original fundador), TEOPAM ofrece diversos servicios
            profesionales al sector público y privado.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Nosotros;
