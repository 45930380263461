import React from 'react'
import logo from '../../assets/logo.png'

const Header = () => {
    return(
        <div className='container-header'>
            <img src={logo} alt='logo-white'/>
            <div className="container-button">
                <p><a href="#"> EN </a> / <a href="#"> ES </a></p>
            </div>
        </div>
    )
}

export default Header