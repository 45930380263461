import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Benefits from '../benefits/Benefits'
import Contact from '../contact/Contact'
import Header from '../header/Header'
import Home from '../home/Home'
import Nav from '../nav/Nav'
import Us from '../us/Us'
import Products from '../products/Products'
import Services from '../services/Services'

const Routes = () => {
    return (
    <Router>
        <Switch>
            <Route path='/contacto'>
                <Contact /> 
            </Route>
            <Route path='/productos'>
                <Products />
            </Route>
            <Route path='/servicios'>
                <Services />
            </Route>
            <Route path='/beneficios'>
                <Benefits />
            </Route>
            <Route path='/nosotros'>
                <Us />
            </Route>
            <Route path='/'>
                <Header />
                <Home />
                <Nav />
            </Route>
        </Switch>
    </Router>
    )
}

export default Routes