import React from "react";
import { Link } from "react-router-dom";
import back from "../../assets/comeback.png";
import iconWhats from "../../assets/whats-icon.svg";

const Contact = () => {
  return (
    <div className="container-contact">
      <div className="header">
        <div className="comeback">
          <Link to="/">
            <img src={back} alt="comeback" />
            <p>INICIO</p>
          </Link>
        </div>
        <p className="title">CONTACTO</p>
        <div className="language-container">
          <p>
            <a href="#"> EN </a> / <a href="#"> ES </a>
          </p>
        </div>
      </div>
      <div className="content">
        <div className="container-left">
          <p>ESTAMOS PARA</p>
          <p>SERVIRTE EN LOS</p>
          <p>SIGUIENTES MEDIOS:</p>
        </div>
        <div className="container-right">
          <p>
            Av. Hidalgo No. 18 piso 2, Santiaguito, Tultitlán, Edo. México, C.P.
            54900
          </p>
          <p>contacto@teopam.com.mx</p>
          <p>55 2162 6301</p>

          <div className="container-whats-number">
            <img src={iconWhats} alt="Icono whatsapp" />

            <p>72 9641 9682</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
