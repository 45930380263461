import React from "react";
import { Pagination } from "antd";
import { useState } from "react";
import ComponentService from "./component-service/ComponentService";

import circle1 from "../../assets/circle1.png";
import circle2 from "../../assets/circle2.png";
import circle3 from "../../assets/circle3.png";
import circle4 from "../../assets/circle4.png";
import circle5 from "../../assets/circle5.png";
import circle6 from "../../assets/circle6.png";
import circle7 from "../../assets/circle7.png";
import circle8 from "../../assets/circle8.png";
import circle9 from "../../assets/circle9.png";
import circle10 from "../../assets/circle10.png";
import circle11 from "../../assets/circle11.png";
import circle12 from "../../assets/circle12.png";
import circle13 from "../../assets/circle13.png";
import circle14 from "../../assets/circle14.png";
import circle15 from "../../assets/circle15.png";
import circle16 from "../../assets/circle16.png";
import circle17 from "../../assets/circle17.png";
import circle18 from "../../assets/circle18.png";
import circle19 from "../../assets/circle19.png";
import circle20 from "../../assets/circle20.png";
import photography01 from "../../assets/photography01.png";
import photography02 from "../../assets/photography02.png";
import photography03 from "../../assets/photography03.png";
import photography04 from "../../assets/photography04.png";
import photography06 from "../../assets/photography06.png";
import photography07 from "../../assets/photography07.png";
import photography08 from "../../assets/photography08.png";
import photography09 from "../../assets/photography09.png";
import hochiki from "../../assets/hochiki.png";
import toa from "../../assets/toa.png";
import notifier from "../../assets/notifier.png";

const Services = () => {
  let [pagination, setPagination] = useState({
    current: 1,
  });

  let titleService01 =
    "Suministro de refacciones para maquinaria y equipos industriales";
  let description01 =
    "Como parte de nuestros servicios contamos con el abastecimiento y adquisición de todo tipo de material directo o indirecto realcionado con los servicios más importantes para el funcionamiento de la industria, así como para el producto final";
  let tooltip01 = {
    tooltip1: "Fabricación bajo diseño.",
    tooltip2: "Costos bajos.",
    tooltip3: "Materiales de calidad.",
    tooltip4: "Mejor tiempo de entrega.",
    tooltip5: "Suministro continuo o programado.",
    tooltip6: "Marcas nacionales y extranjeras.",
  };

  let titleService02 =
    "Desarrollo de proyectos de sistemas electrónicos y automatización";
  let subtitle02 = "SISTEMAS DE AUDIO DISTRIBUIDO";
  let description02 =
    "Su principal función es emitir anuncios audibles de interés general para todos los presentes dentro de diferentes tipos de instalaciones tanto públicas como privadas. También se utilizan generalmente para reproducir música ambiental en espacios comerciales y lugares de esparcimiento.";
  let tooltip02 = {
    tooltip1: "Ingeniería, desarrollo, asesoría e implementación de sistema.",
    tooltip2: "Mantenimiento preventivo y correctivo.",
    tooltip3: "Integración con sistemas electrónicos de seguridad.",
  };

  let subtitle03 = "AUTOMATIZACIÓN CON PLC'S";
  let description03 =
    "Una aplicación importante es el control de acceso, el cual se enfoca en el control automático de activación de cerraduras electromecánicas, garantizando los protocolos de seguridad dentro de un complejo o edifico, tales como centros correccionales, instituciones financieras, centros educativos, corporativos, etc.";
  let tooltip03 = {
    tooltip1: "Interfaces con HMI (pantalla táctil).",
    tooltip2: "Automatización de procesos.",
    tooltip3: "Diseño de sistemas y equipos automatizados.",
    tooltip4: "Control de acceso y seguridad.",
  };

  let titleService04 = "Sistemas contra incendio";
  let subtitle04 = "DETECCIÓN, ALARMA Y SUPRESIÓN DE INCENDIO";
  let description04 =
    "Como parte fundamental y esencial de la seguridad industrial y comercial, se encuentran los sistemas electrónicos de detección y alarma contra incendio que cumplen una de las más importantes funciones de prevención contra eventos que puedan ocasionar una tragedia. La supresión de incendio consta de una red hidráulica que será activada de manera automática por el sistema de detección y alarma.";
  let tooltip04 = {
    tooltip1: "Ingeniería e implementación de sistema.",
    tooltip2: "Mantenimiento preventivo y correctivo.",
    tooltip3: "Integración con sistemas electrónicos de seguridad.",
  };

  let titleService06 = "Instalaciones Eléctricas";
  let description06 =
    "Realizamos servicios profesionales de instalaciones eléctricas industriales y comerciales de media y baja tensión con base a normas oficiales y procedimientos y protocolos de seguridad.";
  let list06 = {
    text1: "- Ducterías, charolas, tableros.",
    text2: "- Cableado, conexión de tableros y equipos.",
    text3: "- Pruebas.",
  };
  let tooltip06 = {
    tooltip1: null,
  };

  let titleService07 = "Subestaciones eléctricas";
  let description07 = "Ofrecemos servicios para subestaciones eléctricas:";
  let list07 = {
    text1: "- Mantenimiento preventivo y correctivo.",
    text2: "- Inspección, revisión y valoración de componentes de desgaste.",
    text3: "- Identificación de puntos críticos.",
    text4: "- Programación, seguimiento y resgistro de mantenimientos.",
    text5: "- Resistencia de aislamiento y resistencia a tierra.",
    text6: "- Inspección de motor y lubricación (equipos mecánicos)",
    text7: "- Reemplazo de componentes dañados y reparación",
  };
  let tooltip07 = {
    tooltip1: null,
  };

  let titleService08 = "Sistemas de CCTV";
  let description08 =
    'Una parte fundamental de los sistemas eléctronicos de seguridad, monitoreo y vigilancia es el sistema de "circuito cerrado de televisión" (CCTV), el cual se puede integrar con otros sistemas de alarmas y alertas, activación de cerraduras electromecánicas, sistemas de sonido, entre otros.';
  let list08 = {
    text1: null,
  };
  let tooltip08 = {
    tooltip1: "Diseño y asesoría de proyecto.",
    tooltip2: "Instalación y puesta en marcha.",
    tooltip3: "Mantenimiento correctivo y preventivo.",
  };

  let titleService09 = "Comunicaciones digitales";
  let subtitle09 = "LAN Y TELEFONÍA";
  let description09 =
    "Ofrecemos servicios profesionales de diseño e implementación de instalaciones para servicios de red, voz y datos, también conocidos como LAN y telefonía. También ofrecemos servicios de telecomunicaciones y sistemas afines";
  let list09 = {
    text1: null,
  };
  let tooltip09 = {
    tooltip1: "Optimizadores de redes wifi.",
    tooltip2: "Redes de fibra óptica: diseño, instalación y conexión",
    tooltip3: "Cableado estructurado.",
    tooltip4: "Sistema ininterrumpido de alimentación (UPS).",
  };

  const onChange = (page) => {
    setPagination({
      current: page,
    });
  };

  return (
    <div className="services-container">
      {pagination.current === 1 && (
        <ComponentService
          title={titleService02}
          subtitle={subtitle02}
          description={description02}
          list01={null}
          list02={null}
          list03={null}
          list04={null}
          list05={null}
          list06={null}
          list07={null}
          tooltip1={tooltip02.tooltip1}
          tooltip2={tooltip02.tooltip2}
          tooltip3={tooltip02.tooltip3}
          tooltip4={null}
          tooltip5={null}
          tooltip6={null}
          tooltip7={null}
          tooltip8={null}
          tooltip9={null}
          circle1={circle8}
          circle2={circle9}
          circle3={circle10}
          circle4={null}
          circle5={null}
          circle6={null}
          circle7={null}
          circle8={null}
          circle9={null}
          photography={photography02}
          logotype2={toa}
          logotype3={null}
          logotype4={null}
          logotype5={null}
          logotype6={null}
          logoName1={"toa"}
          logoName2={null}
          logoName3={null}
          logoName4={null}
          logoName5={null}
        />
      )}
      {pagination.current === 2 && (
        <ComponentService
          title={titleService02}
          subtitle={subtitle03}
          description={description03}
          list01={null}
          list02={null}
          list03={null}
          list04={null}
          list05={null}
          list06={null}
          list07={null}
          tooltip1={tooltip03.tooltip1}
          tooltip2={tooltip03.tooltip2}
          tooltip3={tooltip03.tooltip3}
          tooltip4={tooltip03.tooltip4}
          tooltip5={null}
          tooltip6={null}
          tooltip7={null}
          tooltip8={null}
          tooltip9={null}
          circle1={circle11}
          circle2={circle12}
          circle3={circle13}
          circle4={circle7}
          circle5={null}
          circle6={null}
          circle7={null}
          circle8={null}
          circle9={null}
          photography={photography03}
          logotype2={null}
          logotype3={null}
          logotype4={null}
          logotype5={null}
          logotype6={null}
          logoName1={null}
          logoName2={null}
          logoName3={null}
          logoName4={null}
          logoName5={null}
        />
      )}
      {pagination.current === 3 && (
        <ComponentService
          title={titleService04}
          subtitle={subtitle04}
          description={description04}
          list01={null}
          list02={null}
          list03={null}
          list04={null}
          list05={null}
          list06={null}
          list07={null}
          tooltip1={tooltip04.tooltip1}
          tooltip2={tooltip04.tooltip2}
          tooltip3={tooltip04.tooltip3}
          tooltip4={null}
          tooltip5={null}
          tooltip6={null}
          tooltip7={null}
          tooltip8={null}
          tooltip9={null}
          circle1={circle8}
          circle2={circle9}
          circle3={circle10}
          circle4={null}
          circle5={null}
          circle6={null}
          circle7={null}
          circle8={null}
          circle9={null}
          photography={photography04}
          logotype2={hochiki}
          logotype3={notifier}
          logotype4={null}
          logotype5={null}
          logotype6={null}
          logoName1={"hochiki"}
          logoName2={"notifier"}
          logoName3={null}
          logoName4={null}
          logoName5={null}
        />
      )}
      {pagination.current === 4 && (
        <ComponentService
          title={titleService06}
          subtitle={null}
          description={description06}
          list01={list06.text1}
          list02={list06.text2}
          list03={list06.text3}
          list04={null}
          list05={null}
          list06={null}
          list07={null}
          tooltip1={null}
          tooltip2={null}
          tooltip3={null}
          tooltip4={null}
          tooltip5={null}
          tooltip6={null}
          tooltip7={null}
          tooltip8={null}
          tooltip9={null}
          circle1={null}
          circle2={null}
          circle3={null}
          circle4={null}
          circle5={null}
          circle6={null}
          circle7={null}
          circle8={null}
          circle9={null}
          photography={photography06}
          logotype2={null}
          logotype3={null}
          logotype4={null}
          logotype5={null}
          logotype6={null}
          logoName1={null}
          logoName2={null}
          logoName3={null}
          logoName4={null}
          logoName5={null}
        />
      )}

      {pagination.current === 5 && (
        <ComponentService
          title={titleService08}
          subtitle={null}
          description={description08}
          list01={null}
          list02={null}
          list03={null}
          list04={null}
          list05={null}
          list06={null}
          list07={null}
          tooltip1={tooltip08.tooltip1}
          tooltip2={tooltip08.tooltip2}
          tooltip3={tooltip08.tooltip3}
          tooltip4={null}
          tooltip5={null}
          tooltip6={null}
          tooltip7={null}
          tooltip8={null}
          tooltip9={null}
          circle1={circle14}
          circle2={circle15}
          circle3={circle16}
          circle4={null}
          circle5={null}
          circle6={null}
          circle7={null}
          circle8={null}
          circle9={null}
          photography={photography08}
          logotype2={null}
          logotype3={null}
          logotype4={null}
          logotype5={null}
          logotype6={null}
          logoName1={null}
          logoName2={null}
          logoName3={null}
          logoName4={null}
          logoName5={null}
        />
      )}
      {pagination.current === 6 && (
        <ComponentService
          title={titleService09}
          subtitle={subtitle09}
          description={description09}
          list01={null}
          list02={null}
          list04={null}
          list05={null}
          list06={null}
          list07={null}
          tooltip1={tooltip09.tooltip1}
          tooltip2={tooltip09.tooltip2}
          tooltip3={tooltip09.tooltip3}
          tooltip4={tooltip09.tooltip4}
          tooltip5={null}
          tooltip6={null}
          tooltip7={null}
          tooltip8={null}
          tooltip9={null}
          circle1={circle17}
          circle2={circle18}
          circle3={circle19}
          circle4={circle20}
          circle5={null}
          circle6={null}
          circle7={null}
          circle8={null}
          circle9={null}
          photography={photography09}
          logotype2={null}
          logotype3={null}
          logotype4={null}
          logotype5={null}
          logotype6={null}
          logoName1={null}
          logoName2={null}
          logoName3={null}
          logoName4={null}
          logoName5={null}
        />
      )}
      <div className="pagination-container">
        <Pagination
          defaultCurrent="1"
          total="6"
          pageSize="1"
          onChange={onChange}
        />
        <div>
          <p>TECNO ÓPTIMO DE AMÉRICA S.A. DE C.V.</p>
        </div>
      </div>
    </div>
  );
};

export default Services;
