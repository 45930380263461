import React from 'react';
import { Link } from 'react-router-dom';
import back from '../../assets/comeback.png'
import security from '../../assets/security.png'
import republic from '../../assets/republic.png'
import manufacturing from '../../assets/manufacturing.png'

const Benefits = () => {
    return ( 
    <div className="benefits-container">
        <div className="header">
            <div className="comeback">
                <Link to="/">
                    <img src={back} alt="comeback"/>
                    <p>INICIO</p>
                </Link>
            </div>
            <p className="title">¿POR QUÉ ADQUIRIR CON NOSOTROS?</p>
            <div className="language-container">
                <p><a href="#"> EN </a> / <a href="#"> ES </a></p>
            </div>
        </div>
        <div className="cards-container">
            <div className="security">
                <img src={security} alt="security"/>
                <p>
                    Trabajamos bajo normas oficiales, procedimientos y protocolos de seguridad. 
                </p>
            </div>
            <div className="republic">
                <img src={republic} alt="mexican-republic" />
                <p>
                    Atención a toda la república mexicana.
                </p>
            </div>
            <div className="manufacturing">
                <img src={manufacturing} alt="manufacturing" />
 			    <p>
                    Fabricación de equipo y piezas a medida.  
                </p>
            </div>
        </div>
        <div className="button-container">
            <Link to="/contacto">CONTÁCTANOS</Link>
        </div>
    </div> 
    );
}
 
export default Benefits;