import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <div className="container-nav">
      <div className="container-privacity">
        {/* <a href="#">AVISO DE PRIVACIDAD</a> */}
      </div>
      <div className="container-buttons">
        <Link to="/nosotros" className="buttons">
          NOSOTROS
        </Link>
        <Link to="/beneficios" className="buttons">
          BENEFICIOS
        </Link>
        <Link to="/contacto" id="button-contact">
          CONTACTO
        </Link>
      </div>
    </div>
  );
};

export default Nav;
